import React from "react"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
import Layout from "../../components/layout"
import FreeTrial from '../../components/free-trial'
import TwoColumn from "../../components/two-column"
import ComparisonTable from "../../components/comparison-table"
import Testimonial from '../../components/testimonial'
import SwiperTabs from '../../components/swiper-tabs'
import PlanAction from '../../components/plan-action'
import CustomerJourney from '../../components/customer-journey'
import BadgeContent from "../../components/badge-component"
import {
  bannerContent, 
  comparisonTableContent,
  trialContent,
  planActionContent,
  customerJourneyContent,
  developersContent,
  featureTags,
  trackUsage,
  orderHierarchy
} from "../../lib/producthunt-content"
import { swiperTabsContent } from "../../lib/swiper-tabs-content"
// import { testimonialContent } from "../../lib/testimonial-content"

const badgeData = {
  section: "landing",
  link: true,
  FallBadges: [
    {
      img: require("../../images/high-performance.svg"),
      altText: 'high-performance',
    },
    {
      img: require("../../images/easiest-to-do-bussiness-with-fall.svg"),
      altText: 'easiest-to-do-bussiness-with-fall',
    },
    {
      img: require("../../images/fastest-implementation-fall.svg"),
      altText: 'fastest-implementation-fall',
    },
    {
      img: require("../../images/easiest-setup-fall.svg"),
      altText: 'easiest-setup-fall',
    },
    {
      img: require("../../images/best-est-roi-fall.svg"),
      altText: 'best-est-roi-fall',
    },
    {
      img: require("../../images/user-most-likely-to-recommend-fall.svg"),
      altText: 'user-most-likely-to-recommend-fall',
    },
  ],
}

const ProductHunt = () => (
  <Layout isLandingPage isHeaderTransparent signUpUrlAlt="https://app.billsby.com/registration?key=3D2G2JS9D7" footerUrlAlt="https://app.billsby.com/registration?key=3D2G2JS9D7" freeTrialAmount="$10,000">
    <SEO 
      title="Billsby | Subscription billing software for recurring payments | $5k free trial" 
      description="Rely on Billsby for easy and accurate recurring payments. The most powerful, customizable and easy-to-integrate subscription billing software." 
      url="https://www.billsby.com/landing/carbon"
    />

    <div className="upper-badge producthunt">
      <TwoColumn content={bannerContent}/>
      <BadgeContent content={badgeData} />
    </div>
     <SwiperTabs 
      tabs={swiperTabsContent} 
      title="If you can dream it, we can bill it." 
      description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans." 
    />
     <TwoColumn content={developersContent}/>
     <CustomerJourney content={featureTags} />
     <CustomerJourney content={trackUsage} />
     <CustomerJourney content={orderHierarchy} />
     <PlanAction content={planActionContent} />
     <CustomerJourney content={customerJourneyContent} />
     <ComparisonTable content={comparisonTableContent}/>
     <Testimonial />
     <FreeTrial content={trialContent}/>
  </Layout>
)

export default ProductHunt
